import {
    selectPharmacyById,
    selectSortedPharmacyListByName,
    selectCurrentPharmacyId,
    selectSalesAgent,
    selectPharmacyMapById,
    selectUserHighscore,
    selectCurrentPharmacy,
    selectPaginationData,
} from './selectors';
import {
    loadListByZip,
    loadListBySearchString,
    loadOne,
    changePharmacy,
    clearOtherPharmacy,
    clearAllPharmacy,
} from './actions';

const filterByZipCode = (pharmacyList = [], zipCode) =>
    pharmacyList
        ? pharmacyList.filter((pharmacy) => zipCode === pharmacy.zip)
        : [];

const liveFilterByZipCode = (pharmacyList = [], zipCode) =>
    pharmacyList
        ? pharmacyList.filter((pharmacy) => pharmacy.zip.startsWith(zipCode))
        : [];

const findByName = (pharmacyList = [], name) =>
    pharmacyList
        ? pharmacyList.find((pharmacy) => pharmacy.name === name)
        : null;

export {
    filterByZipCode,
    findByName,
    selectPharmacyById,
    selectPharmacyMapById,
    selectSortedPharmacyListByName,
    selectCurrentPharmacyId,
    liveFilterByZipCode,
    loadListByZip,
    loadListBySearchString,
    loadOne,
    changePharmacy,
    selectSalesAgent,
    selectUserHighscore,
    selectCurrentPharmacy,
    clearOtherPharmacy,
    clearAllPharmacy,
    selectPaginationData,
};
