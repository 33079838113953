/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Form, {Select} from '../../../components/Form';
import Button from '../../../components/Button';

import {
    pharmacyValidator,
    pharmacyUsersValidator,
    reasonPointsAssignmentValidator,
} from '../../../../utils/form-validation';
import {
    selectSortedPharmacyListByName,
    loadListBySearchString,
    selectPaginationData,
} from '../../../../model/pharmacy';
import {Column, Container, Row, Section} from '../../../layout/Grid';
import {
    submitAssignPoints,
    selectReasons,
    loadReasons,
} from '../../../../model/points-assignment';
import formLang from '../../../../lang/forms.lang';
import pointAssignmentLang from '../../../../lang/pages/punktevergabe.lang';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {selectPendingRequests} from '../../../../model/backend-api';
import {LOAD_LIST_BY_SEARCH_STRING} from '../../../../model/pharmacy/actions';
import lang from '../../../../lang/pages/punktevergabe.lang';
import Paragraph from '../../../components/Paragraph';
import Link from '../../../components/Link';
import {routes} from '../../../../model/navigation/routes';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';
import {MAX_POINTS} from '../../../../model/profile';

const validationSchema = yup.object().shape({
    pharmacy: pharmacyValidator(true),
    reason: reasonPointsAssignmentValidator,
    pharmacyUsers: pharmacyUsersValidator,
});

export const PointsAssignmentForm = ({
    onSubmit,
    pharmacyList,
    fetchPharmaciesBySearchString,
    theme,
    isLoading,
    reasons,
    pagination,
}) => {
    const defaultValues = {
        pharmacy: null,
        pharmacyUsers: null,
        reason: null,
    };

    const [searchString, setSearchString] = useState('');
    const [initialPharmacyList, setInitialPharmacyList] = useState([]);

    useEffect(() => {
        setInitialPharmacyList([...initialPharmacyList, ...pharmacyList]);
    }, [pharmacyList]);

    const {control, handleSubmit, errors, watch, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const transformDataForSubmit = ({reason, pharmacyUsers}) => {
        const userIds = pharmacyUsers.map((user) => user.value);
        const reasonId = reason.value;
        onSubmit(reasonId, userIds);
    };

    const pharmacyOptions = initialPharmacyList.map((pharmacy) => ({
        value: pharmacy.id,
        label: `${pharmacy.name}, ${pharmacy.zip}, ${pharmacy.city}`,
        zip: pharmacy.zip,
        city: pharmacy.city,
    }));

    const reasonOptions = reasons.map((reason) => ({
        value: reason.id,
        label: reason.name,
    }));

    const getUsersOptionsOfSelectedPharmacy = (selectedPharmacy) => {
        const found = pharmacyList.find(
            (pharmacy) => pharmacy.id === selectedPharmacy.value
        );
        return found?.users?.map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}, ${user.username}`,
            disabled: user.points >= MAX_POINTS,
        }));
    };

    const selectedPharmacyValue = watch(formLang.pharmacy.name);
    const userOptions = selectedPharmacyValue
        ? getUsersOptionsOfSelectedPharmacy(selectedPharmacyValue)
        : [];

    return (
        <Section theme={theme}>
            <Form
                onSubmit={handleSubmit((data) => {
                    transformDataForSubmit(data);
                    reset(defaultValues);
                })}>
                <Container width="narrow">
                    <Row>
                        <Column md={6}>
                            <Select
                                name={formLang.reasonPointsAssignment.name}
                                label={formLang.reasonPointsAssignment.label}
                                placeholder={
                                    formLang.reasonPointsAssignment.placeholder
                                }
                                sectionTheme={theme}
                                options={reasonOptions}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <ResponsiveSwitch>
                            {(match) => (match.md ? <Column md={6} /> : null)}
                        </ResponsiveSwitch>
                        <Column md={6}>
                            <Select
                                name={formLang.pharmacy.name}
                                label={formLang.pharmacy.label2}
                                placeholder={formLang.zip.placeholder}
                                blurInputOnSelect={true}
                                sectionTheme={theme}
                                noOptionsMessage={() =>
                                    isLoading
                                        ? formLang.pharmacy.loadPharmacies
                                        : formLang.pharmacy.noPharmacies
                                }
                                options={pharmacyOptions}
                                clearValueOnFocus={true}
                                control={control}
                                errors={errors}
                                maxLength={5}
                                onMenuScrollToBottom={() => {
                                    if (
                                        pagination.totalPages !==
                                        pagination.currentPage
                                    ) {
                                        fetchPharmaciesBySearchString(
                                            searchString,
                                            pagination.currentPage + 1
                                        );
                                    }
                                }}
                                onInputChange={(input) => {
                                    if (input.length > 3) {
                                        fetchPharmaciesBySearchString(input, 1);
                                        setSearchString(input);
                                    }
                                }}
                            />
                        </Column>
                        <Column md={6}>
                            <Select
                                name={formLang.pharmacyUsers.name}
                                label={formLang.pharmacyUsers.label}
                                placeholder={formLang.pharmacyUsers.placeholder}
                                noOptionsMessage={() =>
                                    formLang.pharmacyUsers.noUser
                                }
                                sectionTheme={theme}
                                options={userOptions}
                                control={control}
                                errors={errors}
                                multi={true}
                                multiValueLabel={
                                    formLang.pharmacyUsers.multiValueLabel
                                }
                            />
                        </Column>
                        <Column xs={12} className="ap-padding--top">
                            <Button
                                type="submit"
                                label={lang.submitButton}
                                className="ap-button"
                            />
                        </Column>
                    </Row>
                </Container>
                <Container width="narrow" className="text-center">
                    <Row>
                        <Column xs={12}>
                            <Paragraph>
                                {pointAssignmentLang.paragraph2}
                            </Paragraph>
                            <Link
                                icon="arrowForward"
                                href={routes.punktearchiv}>
                                {pointAssignmentLang.link}
                            </Link>
                        </Column>
                    </Row>
                </Container>
            </Form>
        </Section>
    );
};

PointsAssignmentForm.propTypes = {
    onSubmit: PropTypes.func,
    pharmacyList: PropTypes.array,
    reasons: PropTypes.array,
    currentPharmacy: PropTypes.object,
    theme: PropTypes.oneOf(sectionThemeNames),
    fetchPharmaciesBySearchString: PropTypes.func,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object,
};

PointsAssignmentForm.defaultProps = {
    onSubmit: () => {},
    pharmacyList: [],
    reasons: [],
    pagination: {},
    theme: 'default',
    isLoading: false,
    fetchPharmaciesBySearchString: () => {},
};

const ConnectedPointsAssignmentForm = (props) => {
    const dispatch = useDispatch();
    const sortedPharmacyList = useSelector(selectSortedPharmacyListByName);
    const pagination = useSelector(selectPaginationData);
    const pendingRequests = useSelector(selectPendingRequests);
    const reasonsPointsAssignment = useSelector(selectReasons);

    const handleSubmit = (reasonId, userIds) => {
        dispatch(submitAssignPoints(reasonId, userIds));
    };

    useEffect(() => {
        dispatch(loadReasons());
    }, [dispatch]);

    const loadPharmaciesBySearchString = (searchString, currentPage = 1) => {
        dispatch(
            loadListBySearchString(
                searchString,
                currentPage,
                pagination.totalPages
            )
        );
    };

    return (
        <PointsAssignmentForm
            {...props}
            onSubmit={handleSubmit}
            pharmacyUsers={null}
            reasons={reasonsPointsAssignment}
            pharmacyList={sortedPharmacyList}
            pagination={pagination}
            fetchPharmaciesBySearchString={loadPharmaciesBySearchString}
            isLoading={pendingRequests.some(
                (e) => e.type === LOAD_LIST_BY_SEARCH_STRING
            )}
        />
    );
};

export default ConnectedPointsAssignmentForm;
